import type { ReactNode } from 'react'

import { isNil } from 'ramda'

import { Loader } from '@twisto/components/atoms/loader'
import { createRedirectUrl } from '@twisto/utils'

import { appRoutes } from 'customer/routes'

import { useAuthenticatedState } from './authenticated-state.hook'

type Props = {
  children: ReactNode
  ignoreSetSecurityCode?: boolean
}

export const AuthenticationGuard = ({
  children,
  ignoreSetSecurityCode = false,
}: Props) => {
  const { isAuthenticated } = useAuthenticatedState({
    ignoreSetSecurityCode,
  })

  if (isNil(isAuthenticated)) {
    return null
  }

  // redirect client to auth app if customer is not authorized to use customer app
  // and pass redirect param as a fallback url to comeback once user is authenticated
  if (isAuthenticated === false) {
    window.location.assign(
      createRedirectUrl(appRoutes.auth.index.path, window.location.pathname)
    )

    return <Loader />
  }

  return <>{children}</>
}
