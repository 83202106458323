import type { ButtonHTMLAttributes, ReactElement, ReactNode } from 'react'

import type { Theme } from '@emotion/react'

import * as styles from './box.styles'

type Props = {
  children?: ReactNode
  color?: keyof typeof styles.colors
  variant?: keyof typeof styles.variants
  startAdornment?: ReactElement
  endAdornment?: ReactElement
  onClick?: () => void
  className?: string
  arrow?: boolean
}

export const Box = ({
  children,
  className,
  color = 'grey',
  variant = 'default',
  startAdornment,
  endAdornment,
  onClick,
  arrow = false,
  ...other
}: Props) => {
  const css = (theme: Theme) => [
    styles.root,
    styles.variants[variant].box,
    styles.colors[color](theme),
    !!onClick && styles.clickable,
    arrow && styles.arrow(color, theme),
  ]

  const Component = onClick ? 'button' : 'div'
  const componentProps = {
    ...(onClick && {
      onClick,
      type: 'button' as ButtonHTMLAttributes<HTMLButtonElement>['type'],
    }),
    css,
    className,
  }

  const contentStyles = [
    styles.content,
    ...styles.variants[variant].content({
      startAdornment: !!startAdornment,
      endAdornment: !!endAdornment,
    }),
  ]

  return (
    <Component {...componentProps} data-testid="box" {...other}>
      {startAdornment}
      <div css={contentStyles}>{children}</div>
      {endAdornment}
    </Component>
  )
}
