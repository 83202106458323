import type { SecurityCode } from '@twisto/api-graphql'
import { useAuthInfoQuery } from '@twisto/api-graphql'

type AuthenticationState = {
  isAuthenticated: boolean | null
  securityCode: SecurityCode | null
}

type AuthenticatedStateProps = {
  ignoreSetSecurityCode?: boolean
}

export const useAuthenticatedState = ({
  ignoreSetSecurityCode = false,
}: AuthenticatedStateProps): AuthenticationState => {
  const { data, loading } = useAuthInfoQuery()

  const isLoggedIn = data?.authInfo.authState === 'LOGGED_IN'
  const securityCode = data?.authInfo.securityCode
  const isSecurityCodeRequired = securityCode?.isSetRequired === true

  if (loading || !data) {
    return {
      isAuthenticated: null,
      securityCode: null,
    }
  }

  // for frontend, user is authenticated if:
  // - backend returns `LOGGED_IN` as `authState` and...
  // if we ignore security code state by ignoreSetSecurityCode prop
  // otherwise we check is security code is required or not.
  // - enabled prop is false
  const isAuthenticated =
    isLoggedIn && (ignoreSetSecurityCode === true || !isSecurityCodeRequired)

  return {
    isAuthenticated,
    securityCode: data.authInfo.securityCode ?? null,
  }
}
