import type { ReactElement } from 'react'

import { isNil } from 'ramda'

import { Typography } from '@twisto/components/atoms/typography'

import * as styles from './divider.styles'

export type TextBackground = 'primary' | 'secondary'

type Props = {
  text?: ReactElement
  textBackground?: TextBackground
  className?: string
}

export const Divider = ({
  className,
  text,
  textBackground = 'primary',
}: Props) => {
  if (!isNil(text)) {
    return (
      <div className={className} css={styles.textVariant} data-testid="divider">
        <hr css={styles.divider} />
        <Typography
          color="textTertiary"
          css={[
            styles.dividerText,
            styles.dividerTextBackground[textBackground],
          ]}
          fontWeight="medium"
          variant="b4"
        >
          {text}
        </Typography>
      </div>
    )
  }

  return <hr className={className} css={styles.divider} />
}
