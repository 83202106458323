import type { I18n } from '@lingui/core'
import { Trans } from '@lingui/react'

export const numberOfInstallments = (installments: number) => (
  <Trans id="installments" values={{ installments }} />
)

export const numberOfSeconds = (seconds: number) => (
  <Trans id="seconds" values={{ seconds }} />
)

export const numberOfHours = (hours: number) => (
  <Trans id="hours" values={{ hours }} />
)

export const numberOfDays = (days: number) => (
  <Trans id="days" values={{ days }} />
)

export const numberOfMonths = (months: number) => (
  <Trans id="months" values={{ months }} />
)

export const numberOfOffers = (offersCount: number) => (
  <Trans id="shopping.filter.type.offers" values={{ offersCount }} />
)

export const numberOfCredits = (creditsCount: string | number) => (
  <Trans id="plural.credits" values={{ creditsCount }} />
)

export const daysToNumberOfMonths = (days: number) => {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const months = Math.round(days / 30)

  return numberOfMonths(months) as unknown as string
}

export const daysToNumberOfMonthsStr = (days: number, i18n: I18n) => {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const months = Math.round(days / 30)

  return i18n._('months', { months })
}
