import { css } from '@emotion/react'

export const root = css`
  display: flex;
  width: 100%;
`

export const justify = {
  flexStart: css`
    justify-content: flex-start;
  `,
  center: css`
    justify-content: center;
  `,
  flexEnd: css`
    justify-content: flex-end;
  `,
  spaceBetween: css`
    justify-content: space-between;
  `,
  spaceEvenly: css`
    justify-content: space-evenly;
  `,
  spaceAround: css`
    justify-content: space-around;
  `,
}

export const direction = {
  column: css`
    flex-direction: column;
  `,
  row: css`
    flex-direction: row;
  `,
  columnReverse: css`
    flex-direction: column-reverse;
  `,
  rowReverse: css`
    flex-direction: row-reverse;
  `,
}

export const alignItems = {
  flexStart: css`
    align-items: flex-start;
  `,
  center: css`
    align-items: center;
  `,
  flexEnd: css`
    align-items: flex-end;
  `,
  stretch: css`
    align-items: stretch;
  `,
  baseline: css`
    align-items: baseline;
  `,
}

export const wrap = {
  noWrap: css`
    flex-wrap: nowrap;
  `,
  wrap: css`
    flex-wrap: wrap;
  `,
  wrapReverse: css`
    flex-wrap: wrap-reverse;
  `,
}
