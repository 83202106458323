import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const root = (theme: Theme) => css`
  background-color: ${theme.palette.background.primary};
  border-radius: ${styles.borderRadius.sm};
  box-shadow: ${theme.shadows.box};

  ${styles.breakpoint.up('sm')} {
    border-radius: ${styles.borderRadius.md};
  }
`

export const margin = css`
  margin: ${styles.size[8]} 0;

  ${styles.breakpoint.up('sm')} {
    margin: ${styles.size[16]} 0;
  }
`

export const padding = css`
  padding: ${styles.size[8]};

  ${styles.breakpoint.up('sm')} {
    padding: ${styles.size[16]};
  }
`

export const clickable = (theme: Theme) => css`
  cursor: pointer;
  transition: ${styles.transitions.default} box-shadow;

  &:hover,
  &:active {
    box-shadow: ${theme.shadows.boxHover};
  }

  &:focus {
    box-shadow: ${theme.shadows.primaryActive};
  }
`
