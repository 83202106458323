import { useLingui } from '@lingui/react'

import type { I18nLanguage } from '@twisto/base'
import { useAppConfig } from '@twisto/base'
import { formatCurrencyNumber } from '@twisto/utils'

type FormattedCurrencyNumberProps = {
  value: number | string
  currencySignType?: 'default' | 'short'
} & Parameters<typeof formatCurrencyNumber>[1]

export const FormattedCurrencyNumber = ({
  value,
  currency,
  currencySignType = 'default',
  ...options
}: FormattedCurrencyNumberProps) => {
  const {
    i18n: { locale },
  } = useLingui()
  const { currencyCode: regionCurrencyCode } = useAppConfig()

  return (
    <>
      {formatCurrencyNumber(locale as I18nLanguage, {
        ...options,
        currency: currency ?? regionCurrencyCode,
        style: currencySignType === 'default' ? 'currency' : 'decimal',
      })(value)}
      {currencySignType === 'short' &&
        (regionCurrencyCode === 'CZK' ? ' Kč' : ' zł')}
    </>
  )
}
