import type { HTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'

import { styleUtils } from '@twisto/styles'

import * as styles from './flex.styles'

export type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode
  className?: string
  justify?: keyof typeof styles.justify
  direction?: keyof typeof styles.direction
  alignItems?: keyof typeof styles.alignItems
  wrap?: keyof typeof styles.wrap
  fullHeight?: boolean
  component?: 'div' | 'header'
}

export const Flex = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      justify = 'flexStart',
      direction = 'row',
      alignItems = 'flexStart',
      wrap = 'wrap',
      fullHeight = false,
      className,
      component: Component = 'div',
      ...other
    }: Props,
    ref
  ) => {
    const css = [
      styles.root,
      styles.justify[justify],
      styles.direction[direction],
      styles.alignItems[alignItems],
      styles.wrap[wrap],
      fullHeight && styleUtils.height.full,
    ]

    return (
      <Component
        ref={ref}
        className={className}
        css={css}
        data-testid="flex"
        {...other}
      >
        {children}
      </Component>
    )
  }
)
Flex.displayName = 'Flex'
