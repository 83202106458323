import { i18n } from '@lingui/core'

import { IconButton } from '@twisto/components/atoms/button'
import { useIsMobile } from '@twisto/hooks'
import { CloseIcon } from '@twisto/icons'

import * as styles from './modal-closer.styles'

type Props = {
  onClick?: () => void
  to?: string
  position?: keyof typeof styles.positions
  disabled?: boolean
  className?: string
}

export const ModalCloser = ({
  onClick,
  to,
  position = 'absolute',
  disabled,
  className,
}: Props) => {
  const positionStyles = styles.positions[position]
  const closerStyles = [styles.root, positionStyles]
  const isMobile = useIsMobile()

  return (
    <IconButton
      aria-label={/*i18n*/ i18n._('modal.close')}
      className={className}
      color="neutral"
      css={closerStyles}
      data-testid="modal-closer"
      disabled={disabled}
      icon={CloseIcon}
      size={isMobile ? 'small' : 'medium'}
      to={to}
      onClick={onClick}
    />
  )
}
