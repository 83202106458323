import type { SerializedStyles, Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { size } from '@twisto/styles'

import type { TextBackground } from './divider'

export const divider = (theme: Theme) => css`
  height: 1px;
  width: 100%;
  border: none;
  background: ${theme.palette.neutral[200]};
  margin: 0;
`

export const dividerText = css`
  text-transform: uppercase;
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 ${size[16]};
  text-align: center;
`

export const dividerTextBackground: Record<
  TextBackground,
  (theme: Theme) => SerializedStyles
> = {
  primary: (theme) => css`
    background: ${theme.palette.background.primary};
  `,
  secondary: (theme) => css`
    background: ${theme.palette.background.secondary};
  `,
}

export const textVariant = css`
  position: relative;
`
