import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { borderRadius, breakpoint, size } from '@twisto/styles'

export const root = css`
  border-radius: ${borderRadius.sm};
  border: none;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const variants = {
  default: {
    box: css`
      padding: ${size[8]};

      ${breakpoint.up('sm')} {
        padding: ${size[16]};
      }
    `,
    content: ({
      startAdornment,
      endAdornment,
    }: {
      startAdornment: boolean
      endAdornment: boolean
    }) => [
      startAdornment &&
        css`
          margin-left: ${size[8]};

          ${breakpoint.up('sm')} {
            margin-left: ${size[16]};
          }
        `,
      endAdornment &&
        css`
          margin-right: ${size[8]};

          ${breakpoint.up('sm')} {
            margin-right: ${size[16]};
          }
        `,
    ],
  },
  squashed: {
    box: css`
      display: inline-flex;
      padding: ${size[8]};

      ${breakpoint.up('sm')} {
        padding: ${size[8]};
      }
    `,
    content: ({
      startAdornment,
      endAdornment,
    }: {
      startAdornment: boolean
      endAdornment: boolean
    }) => [
      startAdornment &&
        css`
          margin-left: ${size[8]};

          ${breakpoint.up('sm')} {
            margin-left: ${size[8]};
          }
        `,
      endAdornment &&
        css`
          margin-right: ${size[8]};

          ${breakpoint.up('sm')} {
            margin-right: ${size[8]};
          }
        `,
    ],
  },
}

const backgroundColor = (color: keyof typeof colors, theme: Theme) =>
  ({
    primary: theme.palette.brand[100],
    infoPrimary: theme.palette.success[200],
    secondary: theme.palette.additionalColor.purple[100],
    success: theme.palette.success[100],
    warning: theme.palette.warning[100],
    error: theme.palette.error[100],
    grey: theme.palette.neutral[100],
    transparent: 'none',
  })[color]

export const colors = {
  primary: (theme: Theme) => css`
    background: ${backgroundColor('primary', theme)};
    color: ${theme.palette.neutral[800]};
  `,
  infoPrimary: (theme: Theme) => css`
    background: ${backgroundColor('infoPrimary', theme)};
    color: ${theme.palette.brand[500]};
  `,
  secondary: (theme: Theme) => css`
    background: ${backgroundColor('secondary', theme)};
    color: ${theme.palette.neutral[800]};
  `,
  success: (theme: Theme) => css`
    background: ${backgroundColor('success', theme)};
    color: ${theme.palette.neutral[800]};
  `,
  warning: (theme: Theme) => css`
    background: ${backgroundColor('warning', theme)};
    color: ${theme.palette.neutral[800]};
  `,
  error: (theme: Theme) => css`
    background: ${backgroundColor('error', theme)};
    color: ${theme.palette.error[500]};
  `,
  grey: (theme: Theme) => css`
    background: ${backgroundColor('grey', theme)};
    color: ${theme.palette.neutral[800]};
  `,
  transparent: (theme: Theme) => css`
    background: ${backgroundColor('transparent', theme)};
  `,
}

export const clickable = css`
  cursor: pointer;
`

export const content = css`
  flex-grow: 1;
`

const arrowElement = css`
  position: absolute;
  content: '';
  display: block;
  left: 18px;

  width: ${size[16]};
  height: ${size[16]};
  clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
`

export const arrow = (color: keyof typeof colors, theme: Theme) => css`
  margin-top: ${size[16]};
  overflow: visible;
  &::after {
    ${arrowElement}
    top: -${size[16]};
    background: ${backgroundColor(color, theme)};
  }
`
