import { css } from '@emotion/react'

import { breakpoint, size, zIndex } from '@twisto/styles'

export const positions = {
  absolute: css`
    position: absolute;
  `,
  relative: css`
    position: relative;
    top: unset;
    right: unset;

    ${breakpoint.up('sm')} {
      top: unset;
      right: unset;
    }
  `,
}

export const root = css`
  top: ${size[16]};
  right: ${size[24]};
  z-index: ${zIndex.tooltip};
  overflow: hidden;

  ${breakpoint.up('sm')} {
    top: ${size[24]};
    right: ${size[32]};
  }
`
