import type { HTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'

import * as styles from './paper.styles'

type Props = {
  children: ReactNode
  noMargin?: boolean
  noPadding?: boolean
  as?: 'div' | 'article'
} & HTMLAttributes<HTMLDivElement>

export const Paper = forwardRef<HTMLDivElement, Props>(
  (
    { children, noMargin = false, noPadding = false, as = 'div', ...other },
    ref
  ) => {
    const Component = as
    const paperClassName = [
      styles.root,
      !noMargin && styles.margin,
      !noPadding && styles.padding,
      !!other.onClick && styles.clickable,
    ]

    return (
      <Component ref={ref} css={paperClassName} data-testid="paper" {...other}>
        {children}
      </Component>
    )
  }
)

Paper.displayName = 'Paper'
