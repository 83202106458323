import { css } from '@emotion/react'

import { width } from '../width'

export const full = css`
  width: 100%;
`

export const tiny = css`
  width: ${width.container.tiny};
`
